import { Box } from "@mui/material";
import React, { useEffect } from "react";
import CoreChart from "../../components/Chart";
import ApiService from "../../lib/api_service";
import { useState } from "react";
import { useSelector } from "react-redux";
import TradeWidget from "../../components/TradeWidget";
const api = new ApiService();

export default function UserDashboard() {
  const [activeTrade, setActiveTrade] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.user);


  useEffect(() => {
    fetchActiveTrade();
  }, []);
  const fetchActiveTrade = async () => {
    setLoading(true);
    try {
      const response = await api.getRequest("api/v1/plans/active", user.token);
      if (response.statusCode) {
        if (!isEmpty(response.data)) {
          let data = response.data;
          data.direction = "up";
          setActiveTrade(response.data);
        } else {
          console.log("No active trade found.");
          setActiveTrade(null); // or handle no trade accordingly
        }
      }
    } catch (error) {
      console.error("Error fetching signals data:", error);
    } finally {
      setLoading(false);
    }
  };
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  return (
    <Box>
      <TradeWidget trade={activeTrade} refectData={fetchActiveTrade} />
    </Box>
  );
}
