import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    const randomizedValue = (value + Math.floor(Math.random() * 256)) % 256;

    color += `00${randomizedValue.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      mr: 1,
    },
    children: `${name.toUpperCase().split(" ")[0][0]}${
      name.toUpperCase().split(" ")[1][0]
    }`,
  };
}

const UserAvatar = ({ user }) => {
  const phoneNumber = user?.phone;
  const whatsappURL = `https://wa.me/${phoneNumber}`;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      <Avatar {...stringAvatar(`${user?.firstname} ${user?.lastname}`)} />

      <Box
        sx={{
          height: 40,
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2" color="textSecondary">
          {`${user?.firstname} ${user?.lastname}`}
        </Typography>
        <Typography
          variant="caption"
          color="textPrimary"
          component="a"
          href={whatsappURL}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {phoneNumber}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserAvatar;
