import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import mobile from "../assets/Airtel.webp";
import mpesa from "../assets/mpesa.png";
import ApiService from "../lib/api_service";
import { convertToKsh } from "../lib/convertAmount";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const api = new ApiService();
export default function AddFundsModal({ open, handleClose }) {
  const [method, setMethod] = useState(-1);
  const [step, setStep] = useState(1); // To track the current step
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [amount, setAmount] = useState("");
  const [mpesaCode, setMpesaCode] = useState("");
  const [message, setMessage] = useState(null);
  const { user } = useSelector((st) => st.user);
  const [error, setError] = useState(null);

  // Function to handle method selection
  const handleDSetMethod = (val) => {
    setMethod(val);
    setStep(2);
  };

  // Fetch agents from API (mocked)
  // useEffect(() => {
  //   if (step === 2 && method === 1) {
  //     // Simulate API call
  //     const fetchAgents = async () => {
  //       // Replace with your actual API call
  //       const response = await api.getRequest("api/v1/agents", user.token, {
  //         type: "deposit",
  //       });
  //       const data = await response.data;
  //       console.log("DATA", data);
  //       setAgents(data);
  //     };
  //     fetchAgents();
  //   }
  // }, [step, method]);

  // Function to move to the next step
  const handleNextStep = async () => {
    // if (step < 3) {
    //   if (step === 2 && !selectedAgent) {
    //     setError("Please select an agent before you continue");
    //     setTimeout(() => {
    //       setError(null);
    //     }, 4000);
    //     return;
    //   } else {
    //     setStep(step + 1);
    //   }
    // } else {
    // Final submission logic here
    // if (!selectedAgent) {
    //   setError("Please select an agent");
    //   setTimeout(() => {
    //     setError(null);
    //   }, 4000);
    //   return;
    // }
    if (amount.length === 0) {
      setError("Amount in KSH is required");
      setTimeout(() => {
        setError(null);
      }, 4000);
      return;
    }
    // if (mpesaCode.length === 0) {
    //   setError("Mpesa Code is required");
    //   setTimeout(() => {
    //     setError(null);
    //   }, 4000);
    //   return;
    // }

    // Handle saving the data
    const res = await api.postRequestwithBearer(
      "api/v1/payments/sp-deposit",
      JSON.stringify({
        // agentId: selectedAgent.id,
        // mpesaCode,
        amountUSD: amount,
        provider: method === 1 ? "mpesa" : "airtelmoney",
      }),
      user.token
    );
    console.log("RES", res);
    if (!res.status) {
      setError(res.message);
      setTimeout(() => {
        setError(null);
      }, 4000);
      return;
    } else {
      setMessage(res.message);
      setAmount("");
      setMethod(0);
    }

    // handleClose();
    setTimeout(() => {
      handleClose();
      setMessage(null);
    }, 3000);
    // }
  };

  // Function to handle going back to the previous step
  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            ...style,
            width: { xs: "95%", sm: "80%", md: "20%" },
            borderRadius: 1,
          }}
        >
          <Typography id="spring-modal-title" variant="h6" component="h2">
            Add Funds to Your Account
          </Typography>

          {step === 1 && (
            <>
              <Typography id="spring-modal-description" sx={{ mt: 2 }}>
                Choose a service provider:
              </Typography>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                useFlexGap
                sx={{
                  width: "100%",
                  display: { xs: "flex", sm: "flex" },
                  marginTop: 2,
                }}
              >
                <Card
                  key={0}
                  variant="outlined"
                  component={Button}
                  onClick={() => handleDSetMethod(0)}
                  sx={{
                    p: 3,
                    height: "fit-content",
                    width: "100%",
                    background: "none",
                    backgroundColor:
                      method === 0 ? "action.selected" : undefined,
                    borderColor: (theme) => {
                      if (theme.palette.mode === "light") {
                        return method === 0 ? "primary.light" : "grey.200";
                      }
                      return method === 0 ? "primary.dark" : "grey.800";
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      textAlign: "left",
                      flexDirection: { xs: "column", md: "column" },
                      alignItems: { md: "center" },
                      gap: 2.5,
                    }}
                  >
                    <Box
                      component="img"
                      src={mobile} // Replace with the actual path to your image
                      alt="Crypto Deposit"
                      sx={{
                        width: 120,
                        height: 70,
                        borderRadius: "2%",
                      }}
                    />
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      Airtel Money
                    </Typography>
                  </Box>
                </Card>

                <Card
                  key={1}
                  variant="outlined"
                  component={Button}
                  onClick={() => handleDSetMethod(1)}
                  sx={{
                    p: 3,
                    height: "fit-content",
                    width: "100%",
                    background: "none",
                    backgroundColor:
                      method === 1 ? "action.selected" : undefined,
                    borderColor: (theme) => {
                      if (theme.palette.mode === "light") {
                        return method === 1 ? "primary.light" : "grey.200";
                      }
                      return method === 1 ? "primary.dark" : "grey.800";
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      textAlign: "left",
                      flexDirection: { xs: "column", md: "column" },
                      alignItems: { md: "center" },
                      gap: 2.5,
                    }}
                  >
                    <Box
                      component="img"
                      src={mpesa} // Replace with the actual path to your image
                      alt="Agent Deposit"
                      sx={{
                        width: 120,
                        height: 70,
                        borderRadius: "0%",
                      }}
                    />
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      Mpesa
                    </Typography>
                  </Box>
                </Card>
              </Stack>
              {/* {method === 0 && (
                <Box mt={1} mb={1}>
                  <Alert severity="error">
                    Adding Funds by Crypto Coming soon...
                  </Alert>
                </Box>
              )} */}
            </>
          )}

          {/* {step === 2 && method === 1 && (
            <>
              <Typography id="spring-modal-description" sx={{ mt: 2 }}>
                Select an Agent:
              </Typography>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                useFlexGap
                sx={{
                  width: "100%",
                  display: { xs: "flex", sm: "flex" },
                  marginTop: 2,
                  maxHeight: "50vh",
                  overflowY: "auto",
                }}
              >
                {agents.map((agent) => (
                  <Card
                    key={agent.id}
                    variant="outlined"
                    component={Button}
                    onClick={() => setSelectedAgent(agent)}
                    sx={{
                      p: 2,
                      height: "fit-content",
                      width: "100%",
                      background:
                        selectedAgent?.id === agent.id
                          ? "action.selected"
                          : undefined,
                      borderColor:
                        selectedAgent?.id === agent.id
                          ? "primary.light"
                          : "grey.200",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        textAlign: "left",
                        flexDirection: { xs: "column", md: "column" },
                        alignItems: { md: "center" },
                        gap: { xs: 1.5, md: 2.5 },
                      }}
                    >
                      <Box
                        component="img"
                        src={mpesa} // Replace with the actual path to your image
                        alt="Agent Deposit"
                        sx={{
                          width: 100,
                          height: 50,
                          borderRadius: "0%",
                        }}
                      />
                      <Typography variant="body2" fontWeight="bold">
                        {`${agent.firstname} ${agent.lastname}`}
                      </Typography>
                      <Typography
                        variant="h6"
                        color="warning.main"
                        fontWeight="bold"
                      >
                        1 USD = {agent.rates} KSh
                      </Typography>
                      <Typography variant="body2" color="text.primary">
                        {agent.mobileNumber}
                      </Typography>
                    </Box>
                  </Card>
                ))}
                {error && (
                  <Box mt={1} mb={1}>
                    <Alert severity="error">{error}</Alert>
                  </Box>
                )}
              </Stack>
            </>
          )} */}

          {step === 2 && (
            <>
              <Typography id="spring-modal-description" sx={{ mt: 2 }}>
                Enter the amount and MPesa code:
              </Typography>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                useFlexGap
                sx={{
                  width: "100%",
                  display: { xs: "flex", sm: "flex" },
                  marginTop: 2,
                }}
              >
                <TextField
                  label="Amount KSH"
                  variant="outlined"
                  fullWidth
                  value={amount}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^[0-9]*\.?[0-9]*$/;
                    if (regex.test(value) || value === "") {
                      setAmount(value);
                    }
                  }}
                  required
                />
                {/* {amount.trim() !== "" && (
                  <Alert severity="warning">
                    Kindly send{" "}
                    {convertToKsh({
                      agentRate: selectedAgent.rates,
                      amountInUsd: amount,
                    })}{" "}
                    to {selectedAgent.mobileNumber}, then attach Mpesa Code
                    below and submit for processing.
                  </Alert>
                )} */}
                {/* 
                <TextField
                  label="MPesa Code"
                  variant="outlined"
                  fullWidth
                  required
                  value={mpesaCode}
                  onChange={(e) => setMpesaCode(e.target.value.toUpperCase())}
                /> */}
                {message && (
                  <Box mt={1} mb={1}>
                    <Alert severity="success">{message}</Alert>
                  </Box>
                )}
                {error && (
                  <Box mb={2}>
                    <Alert severity="error">{error}</Alert>
                  </Box>
                )}
              </Stack>
            </>
          )}

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginTop: 2,
            }}
          >
            {step > 1 && (
              <Button
                variant="outlined"
                onClick={handlePreviousStep}
                sx={{ marginRight: 4 }}
              >
                Back
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={handleNextStep}
              disabled={step === 1 && method === -1}
            >
              {step === 2 ? "Submit" : "Next"}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
