// src/components/UserDashboardLayout.js
import { useTheme } from "@emotion/react";
import {
  MenuOpen,
  Paid,
  Person,
  SendToMobile,
  Telegram,
  WhatsApp,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Fab,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Link as RouterLink, useLocation } from "react-router-dom";
import AddFundsModal from "../components/AddFundsModal";
import CampaignModal from "../components/CampaignModel";
import Logo from "../components/Logo";
import TradeWidget from "../components/TradeWidget";
import User from "../components/User";
import UserBalance from "../components/UserBalance";
import WithdrawFundsModal from "../components/WithdrawFundsModal";
import ApiService from "../lib/api_service";
import { logOut } from "../store/actions/auth";

const drawerWidth = 240;

const api = new ApiService();

const UserDashboardLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [currentRoute, setCurrentRoute] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [withdrawOpen, setWithdrawOpen] = React.useState(false);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const openMenu = Boolean(anchorEl);
  const [activeCampaign, setActiveCampaign] = useState(null);
  const { user } = useSelector((state) => state.user);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const extractRouteName = (path) => {
    const parts = path.split("/").filter(Boolean); // Split by '/' and remove empty parts
    return parts.length > 1 ? parts[1] : parts[0]; // Extract the part after 'admin'
  };

  useEffect(() => {
    setCurrentRoute(extractRouteName(location.pathname));
    // fetchActiveTrade();
    fetchActiveCampaign();
  }, [location]);

  const handleClose = () => setOpen(!open);
  const handleProfileLogout = () => {
    dispatch(logOut());
    handleMenuClose();
  };
  const handleWithdrawModal = () => setWithdrawOpen(!withdrawOpen);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const handleCloseCampaign = (campaignId) => {
    api
      .postRequestwithBearer(
        `api/v1/campaigns/close/${campaignId}`,
        null,
        user.token
      )
      .then(() => {
        setActiveCampaign(null);
      })
      .catch((error) => {
        console.error("Error closing campaign:", error);
      });
  };
  const fetchActiveCampaign = async () => {
    console.log("ROUTE", currentRoute);
    api
      .getRequest(`api/v1/campaigns/open`, user.token)
      .then((response) => {
        if (
          response.status &&
          response.data.campaign &&
          (currentRoute === null || currentRoute === "signals")
        ) {
          setActiveCampaign(response.data.campaign);
        }
      })
      .catch((error) => {
        console.error("Error closing campaign:", error);
      });
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <AppBar
        position="fixed"
        sx={{
          // width: isMobile ? "100%" : `calc(100% - ${drawerWidth}px)`,
          // ml: isMobile ? 0 : `${drawerWidth}px`,
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: isMobile ? 0 : 2,
        }}
      >
        <Container
          maxWidth={isMobile ? false : "xl"}
          sx={{
            width: isMobile ? "100%" : "auto",
          }}
          disableGutters={isMobile}
        >
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              paddingRight: "4px",
              paddingLeft: "8px",
              justifyContent: "space-between",
              flexShrink: 0,
              // minWidth: !isMobile ?? "40%",
              borderRadius: isMobile ?? "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuOpen />
              </IconButton>
            )}
            <Box
              sx={{
                display: { xs: "flex", md: "flex" },

                gap: 1,
                alignItems: "center",
              }}
            >
              {" "}
              <Typography
                variant="h6"
                sx={{ flexGrow: 1, textTransform: "capitalize" }}
              >
                {currentRoute}
              </Typography>
            </Box>

            <Box
              sx={{
                display: { xs: "flex", md: "flex" },
                gap: 1,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 1,
                  backgroundColor: "background.paper",
                  boxShadow: 3,
                  padding: 1,
                  margin: 1,
                  minWidth: 200,
                }}
              >
                <Button
                  variant="outlined"
                  // color="warning.main" // Updated color
                  startIcon={<SendToMobile />} // Updated icon
                  onClick={handleWithdrawModal}
                >
                  Withdraw
                </Button>
              </Box>
              <Box
                sx={{
                  marginRight: 1,
                  marginLeft: 1,
                  display: { xs: "none", md: "block" },
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<Paid />}
                  onClick={handleClose}
                  size="small"
                  // color="warning.main"
                >
                  Add Funds
                </Button>
              </Box>
              {/* <Box
                sx={{
                  marginRight: isMobile ? 1 : 1,
                  marginLeft: isMobile ? 1 : 1,
                }}

              >
                <Badge badgeContent={4} color="secondary">
                  <Notifications color="action" />
                </Badge>
              </Box> */}
              {/* <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: isMobile ? 1 : 1 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                </IconButton>
              </Tooltip> */}
              <Box
                sx={{
                  display: { xs: "flex", md: "flex" },
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <UserBalance onClick={isMobile ? handleClick : () => {}} />
                {/* {!isMobile && (
                  <TradeWidget
                    trade={activeTrade}
                    refectData={fetchActiveTrade}
                  />
                )} */}
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={(theme) => ({
          width: drawerWidth,
          // backdropFilter: "blur(5px)",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backdropFilter: "blur(5px)",
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
          },
        })}
        anchor="left"
      >
        <Toolbar>
          <Logo />
        </Toolbar>
        <Box sx={{ flexGrow: 1, mt: 2 }}>
          <List>
            {/* <ListItemButton
              variant="outlined"
              component={RouterLink}
              to="/user/dashboard"
              selected={location.pathname === "/user/dashboard"}
              onClick={handleDrawerToggle}
            >
              <ListItemText primary="Dashboard" />
            </ListItemButton> */}
            <ListItemButton
              component={RouterLink}
              to="/active-bots"
              selected={location.pathname === "/active-bots"}
              onClick={handleDrawerToggle}
            >
              <ListItemText
                primary="Active Bots"
                sx={{ textTransform: "capitalize" }}
              />
            </ListItemButton>
            <ListItemButton
              component={RouterLink}
              to="/signals"
              selected={location.pathname === "/signals"}
              onClick={handleDrawerToggle}
            >
              <ListItemText
                primary="AI gold trader"
                sx={{ textTransform: "capitalize" }}
              />
            </ListItemButton>
            <ListItemButton
              component={RouterLink}
              to="/withdrawals"
              selected={location.pathname === "/withdrawals"}
              onClick={handleDrawerToggle}
            >
              <ListItemText
                primary="Withdrawals"
                sx={{ textTransform: "capitalize" }}
              />
            </ListItemButton>

            <ListItemButton
              component={RouterLink}
              to="/deposits"
              selected={location.pathname === "/deposits"}
              onClick={handleDrawerToggle}
            >
              <ListItemText
                primary="Deposits"
                sx={{ textTransform: "capitalize" }}
              />
            </ListItemButton>
            <ListItemButton
              component={RouterLink}
              to="/referrals"
              selected={location.pathname === "/referrals"}
              onClick={handleDrawerToggle}
            >
              <ListItemText
                primary="Referrals"
                sx={{ textTransform: "capitalize" }}
              />
            </ListItemButton>

            <ListItemButton
              component={RouterLink}
              to="/settings"
              selected={location.pathname === "/settings"}
              onClick={handleDrawerToggle}
            >
              <ListItemText
                primary="Settings"
                sx={{ textTransform: "capitalize" }}
              />
            </ListItemButton>
          </List>
        </Box>

        {/* <Divider />
        <Box sx={{ p: 2 }}>
          <Balance balance={12345.67} />
        </Box> */}
        <Divider />
        <Box sx={{ p: 2 }}>
          <User />
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: isMobile ? 0.7 : 3,
          width: `calc(100% - ${drawerWidth}px)`,
          position: "relative",
          pb: { xs: 12, sm: 3 },
        }}
      >
        <Toolbar />
        {/* {isMobile && ( */}
        {/* )} */}
        <Outlet />
        <Box
          sx={{
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            bottom: isMobile ? 16 : 24,
            right: isMobile ? 16 : 24,
            zIndex: 1300,
            gap: 1,
          }}
        >
          {" "}
          <Fab
            color="primary"
            aria-label="telegram"
            href="https://t.me/freefx_AItrading" // Link to your Telegram
            target="_blank" // Open Telegram in a new tab
          >
            <Telegram />
          </Fab>
          <Fab
            color="success"
            aria-label="telegram"
            href="https://wa.link/o0916b" // Link to your Telegram
            target="_blank" // Open Telegram in a new tab
          >
            <WhatsApp />
          </Fab>
        </Box>
      </Box>
      <AddFundsModal handleClose={handleClose} open={open} />
      <WithdrawFundsModal
        handleClose={handleWithdrawModal}
        open={withdrawOpen}
      />
      {activeCampaign ? (
        <CampaignModal
          campaign={activeCampaign}
          onClose={() => handleCloseCampaign(activeCampaign.id)}
        />
      ) : (
        <></>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box
          sx={{
            borderRadius: 1,
            backgroundColor: "background.paper",
            // boxShadow: 3,
            // padding: 1,
            margin: 1,
            minWidth: 200,
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <Paid fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add Funds</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleWithdrawModal();
              handleMenuClose();
            }}
            sx={{}}
          >
            {" "}
            <ListItemIcon>
              <SendToMobile fontSize="small" />
            </ListItemIcon>
            <ListItemText>Withdraw</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};

export default UserDashboardLayout;
