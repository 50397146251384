import React, { useEffect, useState } from "react";
import { Box, Typography, Chip, Grid, Divider } from "@mui/material";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import ApiService from "../lib/api_service";
import { useSelector } from "react-redux";
import TradeTimeWidget from "./TradeTimeWidget";
import { AutoAwesome } from "@mui/icons-material";

const api = new ApiService();
const TradeWidget = ({}) => {
  const [trades, setActiveTrade] = useState([]);
  const [loading, setLoading] = useState(true);

  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    fetchActiveTrade();
  }, []);

  const fetchActiveTrade = async () => {
    setLoading(true);
    try {
      const response = await api.getRequest("api/v1/plans/active", user.token);
      if (response.statusCode) {
        if (!isEmpty(response.data)) {
          let data = response.data;
          data.direction = "up";
          setActiveTrade(response.data);
        } else {
          console.log("No active trade found.");
          setActiveTrade([]); // or handle no trade accordingly
        }
      }
    } catch (error) {
      console.error("Error fetching signals data:", error);
    } finally {
      setLoading(false);
    }
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  return (
    <Box
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        borderRadius: 1,
        border: "1px solid",
        borderColor: "divider",
        p: 0.5,
        m: 1,
      }}
    >
      {loading ? (
        <>
          {" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              p: 0.5,
              m: 1,
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Checking...
            </Typography>
          </Box>
        </>
      ) : (
        <>
          {trades.length > 0 ? (
            <Box>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontWeight: "bold", m: 1 }}
              >
                Active Bots{" "}
              </Typography>
              <Divider />
              <Grid container spacing={2}>
                {trades.map((trade, index) => (
                  <Grid item xs={12} sm={6} md={2} key={index}>
                    <Box
                      sx={{
                        display: { xs: "flex", sm: "flex" },
                        justifyContent: "space-evenly",
                        // alignItems: "",
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                        borderRadius: 1,
                        border: "1px solid",
                        borderColor: "divider",
                        p: 0.5,
                        m: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <TrendingUpIcon sx={{ color: "success.main" }} />

                        <Typography variant="body1" sx={{ ml: 1 }}>
                          {trade?.signal?.name}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2" color="textPrimary">
                          Amount: Ksh{trade.amount}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <AutoAwesome
                            sx={{
                              fontSize: 16,
                              mr: 0.2,
                              ml: 0.2,
                              color: "primary.main",
                            }}
                          />
                          <Typography variant="body2" color="primary.main">
                            KES {trade?.signal?.timeframe?.rate} daily for{" "}
                            {Number.parseInt(trade?.signal?.timeframe?.period)}{" "}
                            days
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <HourglassTopIcon
                            sx={{
                              fontSize: 16,
                              mr: 0.2,
                              color: "warning.main",
                            }}
                          />
                          <TradeTimeWidget trade={trade} />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                p: 0.5,
                m: 1,
              }}
            >
              <Typography variant="body2" color="textSecondary">
                No active trade
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default TradeWidget;
