import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../lib/api_service";
import StatusChip from "./StatusChip";
import UpdateTransactionModal from "./UpdateModal";
import UserAvatar from "./UserAvatar";
import WithdrawWidget from "./WIthdrawWidget";

const api = new ApiService();
const AdminTransactionsTable = ({ type }) => {
  const [open, setOpen] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = useSelector((st) => st.user);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const fetchData = useCallback(
    async (page, pageSize, searchTerm) => {
      setLoading(true);
      try {
        const response = await api.getRequest("api/v1/payments", user.token, {
          type: type,
          page: page + 1,
          limit: pageSize,
          search: searchTerm,
        });
        if (response.status) {
          setData(response.data.transactions);
          setTotalRows(response.data.totalItems);
        }
      } catch (error) {
        console.error("Error fetching transactions data:", error);
      } finally {
        setLoading(false);
      }
    },
    [user, type]
  );

  useEffect(() => {
    fetchData(paginationModel.page, paginationModel.pageSize, searchTerm);
  }, [paginationModel, searchTerm]);

  const handleViewClick = (transaction) => {
    // Implement the view action
    console.log("Viewing transaction:", transaction);
  };

  const handleUpdateClick = (transaction) => {
    // Implement the update action
    setTransaction(transaction);
    handleClose();
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 100 },
    {
      field: "user",
      headerName: "User",
      editable: false,
      flex: 1,
      renderCell: (params) => <UserAvatar user={params.row.user} />,
    },
    {
      field: "amountUSD",
      headerName: "Amount (USD)",
      editable: false,
      flex: 1,
    },
    {
      field: "amountKSH",
      headerName: "Amount (KSH)",
      editable: false,
      flex: 1,
      renderCell: (params) => (
        <WithdrawWidget amount={params.row.amountKSH} type={type} />
      ),
    },
    { field: "mpesaCode", headerName: "Mpesa Code", editable: false, flex: 1 },
    {
      field: "transactionType",
      headerName: "Transaction Type",
      editable: false,
      flex: 1,
    },
    { field: "type", headerName: "Type", editable: false, flex: 1 },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      flex: 1,
      renderCell: (params) => <StatusChip status={params.row.status} />,
    },

    {
      field: "agent",
      headerName: "Agent",
      editable: false,
      flex: 1,
      renderCell: (params) => <UserAvatar user={params.row.agent} />,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      editable: false,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityIcon />}
          label="View"
          onClick={() => handleViewClick(params.row)}
        />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Update"
          onClick={() => handleUpdateClick(params.row)}
        />,
      ],
    },
  ];
  const handleClose = () => setOpen(!open);
  const onFilterChange = useCallback((filterModel) => {
    if (filterModel.quickFilterValues.length > 0) {
      setSearchTerm(filterModel.quickFilterValues[0] || "");
    } else {
      setSearchTerm("");
    }
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        sx={{ width: "100%" }}
        rows={data}
        columns={columns}
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        paginationModel={paginationModel}
        rowCount={totalRows}
        paginationMode="server"
        disableColumnFilter
        filterDebounceMs={500}
        disableColumnSelector
        disableDensitySelector
        loading={loading}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        onFilterModelChange={onFilterChange}
        onPaginationModelChange={setPaginationModel}
      />
      <UpdateTransactionModal
        transaction={transaction}
        handleClose={handleClose}
        open={open}
        type={type}
      />
    </div>
  );
};

export default AdminTransactionsTable;
