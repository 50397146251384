import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function TradeTimeWidget({ trade, refetchData }) {
  const [timeRemaining, setTimeRemaining] = useState(0);

  useEffect(() => {
    if (!trade?.createdAt || !trade?.signal?.timeframe?.period) return;

    const interval = setInterval(() => {
      const timePassed = Math.floor(
        (Date.now() - new Date(trade.createdAt)) / 1000
      );
      const periodInSecs =
        Number.parseFloat(trade.signal.timeframe.period) * 24 * 60 * 60; // Convert days to seconds
      const timeLeft = periodInSecs - timePassed;

      if (timeLeft >= 0) {
        setTimeRemaining(timeLeft);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [trade]);

  const formatTime = (seconds) => {
    if (seconds <= 0 || isNaN(seconds)) return "0d 0h 0m 0s";

    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const mins = Math.floor((seconds % (60 * 60)) / 60);
    const secs = seconds % 60;

    return `${days}d ${hours}h ${mins}m ${secs}s`;
  };

  return (
    <Typography variant="body2" color="warning.main">
      {timeRemaining > 0 ? formatTime(timeRemaining) : "Time expired"}
    </Typography>
  );
}
